import React from 'react';
import './../../css/all.css'
import Home from './Home';

function HomeFunc() {
  return (
    <div className="">
<Home/>

    </div>
  );
}

export default HomeFunc;
