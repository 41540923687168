
import React from 'react';
import './../../../css/all.css'

function PageNotFound() {
  return (
    <div className="nono">
      
<div>

  <h1>not found page</h1>
</div>

    </div>
  );
}

export default PageNotFound;
