import './../../css/all.css'
import News from './News';
import React,{useEffect,useState} from 'react';
import {useLocation} from 'react-router-dom'
import axiosConfig from '../../config/AxiosConfig'
import {useDispatch,useSelector} from "react-redux";
import * as actionTypes from "../../store/Actions";

import Image1 from '../../image/d1.jpg'
import Image2 from '../../image/d2.jpg'
import Image3 from '../../image/d3.jpg'
import Image4 from '../../image/d5.jpg'
import Image5 from '../../image/d6.jpg'
import Image6 from '../../image/d7.jpg'
import Image7 from '../../image/f1.png'
import Image8 from '../../image/f4.jpeg'

function NewsArr() {

  const [ArcitavState,setArcitavState] = useState({arr:[]})
  const [refresh,setrefresh] = useState(true)
  const navigate = useLocation();
  const dispatch = useDispatch();

  function refreshes(){
    setrefresh(!refresh)
  }
  
    useEffect(() => {
      window.scrollTo(0, 0)
      getcomments()
    }, [refresh])


    async function getcomments(){
      try{
       await axiosConfig.get("/comment/c*get*c",
       {params:{data:navigate.state.e.id}})
        .then((res) => {
        dispatch({type:actionTypes.GET_COMMENT,data:res.data.com})
        })
        .catch((err) => {
          console.log("error3",err)
        });
      }catch(err){
  console.log(err)
      }
    }
     
   



let arr = [{title:"מהפכה בעולם התוספות - תוספות שיער בשיטת התפירה",
img:Image1,
id:3,
txtlong:"מהן השיטות הנפוצות להרכבת תוספות שיער? איזו שיטה אינה מסבה נזק לשיער? האם יש חידושים בתחום? אם את עדיין מדביקה ומלחימה - זה הזמן להכיר את השיטה המובילה להרכבת תוספות שיער בשיטת התפירה.נשים רבות סובלות משיער דליל או שיער שאינו בעל נפח. אחת השיטות להשיג מראה בריא ומלא היא באמצעות תוספות שיער, כאשר ישנן מספר שיטות לתוספות, חלקן מזיקות לשיער וחלקן לא. במאמר הבא נסקור את השיטות וננסה להבין מדוע חלק מהשיטות מזיקות וחלק לא ואיזו שיטה היא המומלצת מכולן כדי להימנע מנזקים לשיער ולקרקפת.",

date:'MAY 25, 2020',
txt:"שיטת התפירה החדשנית נחשבת לאחת השיטות המתקדמות והחדשות בתחום תוספות השיער. בשיטה זו לוקחים תוספות שיער ותופסים אותן אחת אחת לשיער הצמוד לקרקפת, באמצעות תפירה מקצועית שאינה מכאיבה כלל..",
sidetitle:"יתרונות שיטת התפירה",
sidetxt:"לביצוע תוספות שיער בשיטת התפירה יתרונות רבים. היתרון הראשון והברור מאליו הוא שאין כל הרס לשיער והשיער ממשיך לארוך ללא נזק. עוד יתרון הוא שבעת ההרמה, התוספת לא מתקצרת ונשארת באורך המקורי שלה. היתרון השלישי שמדובר בשיטת תפירה ללא צמות. התוצאה המתקבלת הנה טבעית, עד כדי כך שלא מורגש כי מדובר בכלל על תוספת שיער."},
,{title:" לא לקחת סיכון בשום אופן -תוספות שיער ללא נזק",
id:5,
txt:"ללא הלחמה וללא הדבקה – תוספות שיער בשיטת התפירה.אל תוותרי על אחד מסממני היופי הנשי – ועל מראה צעיר ובריא – זה הזמן להנות מתוספות שיער בלי לסבול מכאבים ובלי לגרום לשיער נזקים מיותרים!"
,date:'MAY 25, 2020',img:Image4},
{title:" תוספות שיער - תוספות איכותיות בייאבוא אישי",
img:Image2,date:'MAY 25, 2020',
id:2,
txt:"מהן השיטות הנפוצות להרכבת תוספות שיער? איזו שיטה אינה מסבה נזק לשיער? האם יש חידושים בתחום? אם את עדיין מדביקה ומלחימה - זה הזמן להכיר את השיטה המובילה להרכבת תוספות שיער בשיטת התפירה.",
txtlong:"מהן השיטות הנפוצות להרכבת תוספות שיער? איזו שיטה אינה מסבה נזק לשיער? האם יש חידושים בתחום? אם את עדיין מדביקה ומלחימה - זה הזמן להכיר את השיטה המובילה להרכבת תוספות שיער בשיטת התפירה.נשים רבות סובלות משיער דליל או שיער שאינו בעל נפח. אחת השיטות להשיג מראה בריא ומלא היא באמצעות תוספות שיער, כאשר ישנן מספר שיטות לתוספות, חלקן מזיקות לשיער וחלקן לא. במאמר הבא נסקור את השיטות וננסה להבין מדוע חלק מהשיטות מזיקות וחלק לא ואיזו שיטה היא המומלצת מכולן כדי להימנע מנזקים לשיער ולקרקפת.",
sidetitle:"תוספות שיער – אילו שיטות יש להרכבת תוספות השיער?",
sidetxt:"ישנן כמה שיטות לביצוע תוספות שיער - כאשר לא כל המספרות מבצעות את כולן וחלקן מצריכות מיומנות וניסיון של שנים כדי שיבוצעו כשורה."}
,{title:"תוספות שיער טבעיות בשיטה ייחודית",
txt:"במספרת מלי וסאמיה אנו מבצעים תוספות שיער בשיטת התפירה. בשיטה זו לא מבצעים הלחמה או הדבקה, לא מזיקים לשיער כלל ונהנים ממראה טבעי וייחודי של שיער שופע ."
,sidetitle:"תוספות שיער ללא נזק",
id:1,
img:Image3,date:'MAY 25, 2020',
sidetxt:" חולמת על תוספות שיער אך חוששת מהנזק שהן יכולות לגרום לשיער? צודקת בהחלט. אין שום סיבה לסבול מנזקים כשרוצים ליהנות מיופי  ולכן תוספות שיער ללא נזק בשיטת התפירה מחייבות מיומנות רבה  ובכך אנו מתמחים. אנשי המקצוע שלנו מרכיבים את תוספות השיער בשיטה אורגנית - בתפירה, ללא צמות, ללא הדבקה וללא הלחמה. השיטה הזו הוכיחה את עצמה כמוצלחת ביותר מבחינת תוצאות וגם מבחינת השמירה על השיער הטבעי.והתוצאות? תוספות שיער טבעיות בשיטה אורגנית המקנה מראה טבעי, שופע, מלא ובריא של שיער. השיטה לא כואבת – לא מרגישים אותה כלל ואחת לשלושה חודשים יש לבצע הרמה של התוספת. את התוספת ניתן  לצבע, לעשות פסים, בייביליס ולהתייחס לתוספת כאל שיער טבעי לכל דבר.",
list:"• נתחיל עם השיטה הידועה מכולן והיא ההלחמה – כאן מדובר על שיטה יעילה וחזקה, בה מלחימים באמצעות מלחם ייעודי את התוספת לשורש השערה. אך שיטה זו  מסבה נזק לשיער ובהורדת התוספות ניתן לראות את הנזקים באופן ישיר. • שיטה נוספת שנחשבה בעבר לשימושית במיוחד היא שיטת ההדברה. בשיטה זו מדביקים את התוספת לשיער הטבעי בצמוד לקרקפת, גם כאן מדובר על יעילות אך לא על איכות – בשיטה זו נגרם גם כן נזק לשיער.• שיטה שאינה מזיקה אך לא נחשבת ליעילה היא שיטת הקליפסים – מצמידים את תוספות השיער באמצעות קליפסים לקרקפת. השיטה לא נחשבת לנוחה כיוון שתוספת השיער עלולה ליפול והיא מורגשת מאוד באזור הקרקפת.וכאן נכנסת לתמונה שיטת התפירה – שמביאה אליך את הטוב מכל העולמות."}]



  return (
    <div className="">
<News refreshes={refreshes} arr={arr}/>
    </div>
  );
}

export default NewsArr;
