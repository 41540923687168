
export const ADD_COMMENT = "ADD_COMMENT";


export const DELET_COMMENT = "DELET_COMMENT";

export const GET_COMMENT = "GET_COMMENT";
export const CONNECT = "CONNECT";
export const LOGUOT = "LOGUOT";

export const STILL = "STILL";

