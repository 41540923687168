import React from 'react';
import './../../../css/all.css'


function Titlesmall(props) {

  return (
    <div className='titlepost'>

  <p className="titlelittle">-{props.header}-</p>

    </div>
  );
}

export default Titlesmall;
