import React from 'react';
import '../../../css/all.css'
import '../../../css/home.css'



function Callnow(props) {
  return (
    <div className="bgbi flexcenter ">
        <div className="wordsheader flexcenter">
            <h1 className="header">לייעוץ חינם עם מומחית בכל נושא</h1>
            <p className="p">התקשרי עכשיו</p>
            <a href="" className="Link cwhite">
            <div className="button2">03-6815167</div></a>
        </div>

   
    </div>
  );
}

export default Callnow;
